import request from '@/utils/request'

export function fetchUser(query) {
  return request({
    url: '/v1/admins/users',
    method: 'get',
    params: query
  })
}

export function fetchUserSignin(id) {
  return request({
    url: '/v1/admins/users/' + id + "/signin-records",
    method: 'get',
  })
}

export function updateUser(id, data) {
  return request({
    url: '/v1/admins/users/' + id,
    method: 'patch',
    data
  })
}

export function fetchOrganization(query) {
  return request({
    url: '/v1/admins/organizations',
    method: 'get',
    params: query
  })
}

export function fetchOneOrganization(id) {
  return request({
    url: '/v1/admins/organizations/' + id,
    method: 'get',
  })
}

export function createOrganization(data) {
  return request({
    url: '/v1/admins/organizations',
    method: 'post',
    data
  })
}

export function updateOrganization(id, data) {
  return request({
    url: '/v1/admins/organizations/' + id,
    method: 'put',
    data
  })
}

export function deleteOrganization(id) {
  return request({
    url: '/v1/admins/organizations/' + id,
    method: 'delete',
  })
}

export function fetchUserService(query) {
  return request({
    url: '/v1/admins/user-services',
    method: 'get',
    params: query
  })
}

export function createUserService(data) {
  return request({
    url: '/v1/admins/user-services',
    method: 'post',
    data
  })
}

export function approve(data) {
  return request({
    url: '/v1/admins/user-services/approve',
    method: 'post',
    data
  })
}

export function preview(query) {
  return request({
    url: '/v1/admins/user-services/preview',
    method: 'get',
    params: query
  })
}

export function updateUserService(id, data) {
  return request({
    url: '/v1/admins/user-services/' + id,
    method: 'put',
    data
  })
}

export function fetchBrand(query) {
  return request({
    url: '/v1/admins/brands',
    method: 'get',
    params: query
  })
}

export function createBrand(data) {
  return request({
    url: '/v1/admins/brands',
    method: 'post',
    data
  })
}

export function createBrandBatch(data) {
  return request({
    url: '/v1/admins/brands/batch',
    method: 'post',
    data
  })
}

export function updateBrand(id, data) {
  return request({
    url: '/v1/admins/brands/' + id,
    method: 'put',
    data
  })
}

export function deleteBrand(id) {
  return request({
    url: '/v1/admins/brands/' + id,
    method: 'delete',
  })
}

export function fetchItem(query) {
  return request({
    url: '/v1/admins/items',
    method: 'get',
    params: query
  })
}

export function fetchUserBrand(id) {
  return request({
    url: '/v1/admins/user-brands/user/' + id,
    method: 'get',
  })
}

export function fetchUserBrandOrg(id) {
  return request({
    url: '/v1/admins/user-brands/organization/' + id,
    method: 'get',
  })
}

export function updateUserBrand(id, data) {
  return request({
    url: '/v1/admins/user-brands/user/' + id,
    method: 'put',
    data
  })
}

export function updateUserBrandOrg(id, data) {
  return request({
    url: '/v1/admins/user-brands/organization/' + id,
    method: 'put',
    data
  })
}

export function fetchUserBrandType(userId) {
  return request({
    url: '/v1/admins/user-brands/type/' + userId,
    method: 'get',
  })
}

export function fetchUserItem(id) {
  return request({
    url: '/v1/admins/user-items/user/' + id,
    method: 'get',
  })
}

export function fetchUserItemOrg(id) {
  return request({
    url: '/v1/admins/user-items/organization/' + id,
    method: 'get',
  })
}

export function updateUserItem(id, data) {
  return request({
    url: '/v1/admins/user-items/user/' + id,
    method: 'put',
    data
  })
}

export function updateUserItemOrg(id, data) {
  return request({
    url: '/v1/admins/user-items/organization/' + id,
    method: 'put',
    data
  })
}

export function fetchUserNlp(id) {
  return request({
    url: '/v1/admins/nlp-analysis/user/' + id,
    method: 'get',
  })
}

export function updateUserNlp(id, data) {
  return request({
    url: '/v1/admins/nlp-analysis/user/' + id,
    method: 'put',
    data
  })
}

export function createDrive(data) {
  return request({
    url: '/v1/admins/drive',
    method: 'post',
    data
  })
}

export function deleteDrive(type, date) {
  return request({
    url: '/v1/admins/drive/' + type + "/" + date,
    method: 'delete',
  })
}

export function createVideo(data) {
  return request({
    url: '/v1/admins/video',
    method: 'post',
    data
  })
}

export function deleteVideo(id) {
  return request({
    url: '/v1/admins/video/' + id,
    method: 'delete',
  })
}

export function fetchBasicRecord(query) {
  return request({
    url: '/v1/admins/analysis-basic/records',
    method: 'get',
    params: query
  })
}

export function deleteBasic(type, period, date) {
  return request({
    url: '/v1/admins/analysis-basic/' + type + '/' + period + '/' + date,
    method: 'delete',
  })
}

export function fetchBasicTaggerDetailBest(query) {
  return request({
    url: '/v1/admins/analysis-basic/tagger/details/best',
    method: 'get',
    params: query
  })
}

export function saveBasicTaggerDetailBest(data) {
  return request({
    url: '/v1/admins/analysis-basic/tagger/details/best',
    method: 'post',
    data
  })
}

export function fetchBasicTaggerDetailNoDetection(query) {
  return request({
    url: '/v1/admins/analysis-basic/tagger/details/no-detection',
    method: 'get',
    params: query
  })
}

export function fetchBasicTaggerDetailBrand(query) {
  return request({
    url: '/v1/admins/analysis-basic/tagger/details/brand',
    method: 'get',
    params: query
  })
}

export function updateBasicTaggerDetail(data, id) {
  return request({
    url: '/v1/admins/analysis-basic/tagger/details/' + id,
    method: 'put',
    data
  })
}

export function taggerBasic(period, date) {
  return request({
    url: '/v1/admins/analysis-basic/tagger/' + period + "/" + date,
    method: 'post',
  })
}

export function taggerBasicAddition(data) {
  return request({
    url: '/v1/admins/analysis-basic/tagger/additional-date',
    method: 'post',
    data
  })
}

export function confirmTaggerBasic(period, date) {
  return request({
    url: '/v1/admins/analysis-basic/confirm/' + period + '/' + date,
    method: 'post',
  })
}

export function transformBasic(period, date, data) {
  return request({
    url: '/v1/admins/analysis-basic/transform/' + period + '/' + date,
    method: 'post',
    data
  })
}

export function fetchUserItems(date, query) {
  return request({
    url: '/v1/admins/analysis-user-item/' + date,
    method: 'get',
    params: query,
  })
}

export function fetchUserItemsUser(date) {
  return request({
    url: '/v1/admins/analysis-user-item/' + date + '/user',
    method: 'get',
  })
}

export function fetchUserItemsNotInUser(date, query) {
  return request({
    url: '/v1/admins/analysis-user-item/' + date + '/not-in-user',
    method: 'get',
    params: query,
  })
}

export function fetchUserReport(query) {
  return request({
    url: '/v1/admins/user-report',
    method: 'get',
    params: query,
  })
}

export function createUserReport(data) {
  return request({
    url: '/v1/admins/user-report',
    method: 'post',
    data
  })
}

export function updateUserReport(id, data) {
  return request({
    url: '/v1/admins/user-report/' + id,
    method: 'put',
    data
  })
}

export function deleteUserReport(id) {
  return request({
    url: '/v1/admins/user-report/' + id,
    method: 'delete',
  })
}

export function fetchSummary(query) {
  return request({
    url: '/v1/admins/summary',
    method: 'get',
    params: query,
  })
}

export function fetchSummaryDetails(id, query) {
  return request({
    url: '/v1/admins/summary/' + id + '/details',
    method: 'get',
    params: query,
  })
}

export function updateSummary(id, data) {
  return request({
    url: '/v1/admins/summary/' + id,
    method: 'put',
    data
  })
}

export function deleteSummary(id) {
  return request({
    url: '/v1/admins/summary/' + id,
    method: 'delete',
  })
}

export function fetchImageTrendMap(query) {
  return request({
    url: '/v1/admins/image-trend-map',
    method: 'get',
    params: query,
  })
}

export function fetchImageTrendMapDetail(query) {
  return request({
    url: '/v1/admins/image-trend-map/brand-item',
    method: 'get',
    params: query
  })
}

export function exportImageTrendMap(query) {
  return request({
    url: '/v1/admins/image-trend-map/export',
    method: 'get',
    params: query,
  })
}

export function exportImageTrendMapDetail(query) {
  return request({
    url: '/v1/admins/image-trend-map/brand-item/export',
    method: 'get',
    params: query,
  })
}

export function syncVisualFiles(data) {
  return request({
    url: '/v1/admins/visual-file/sync',
    method: 'post',
    data
  })
}

export function fetchCategory(query) {
  return request({
    // TODO: separate fetch api to both admin & public route
    url: '/v1/admins/category',
    method: 'get',
    params: query,
  })
}

export function createCategory(data) {
  return request({
    url: '/v1/admins/category',
    method: 'post',
    data
  })
}

export function updateCategory(id, data) {
  return request({
    url: '/v1/admins/category/' + id,
    method: 'put',
    data
  })
}

export function fetchNLPTask(query) {
  return request({
    url: '/v1/admins/nlp-task',
    method: 'get',
    params: query,
  })
}

export function deleteNLPTask(id) {
  return request({
    url: '/v1/admins/nlp-task/' + id,
    method: 'delete',
  })
}

export function fetchNotification(query) {
  return request({
    url: '/v1/admins/notifications',
    method: 'get',
    params: query,
  })
}

export function createNotification(data) {
  return request({
    url: '/v1/admins/notifications',
    method: 'post',
    data
  })
}

export function deleteNotification(id) {
  return request({
    url: '/v1/admins/notifications/' + id,
    method: 'delete',
  })
}

export function fetchTask(query) {
  return request({
    url: '/v1/admins/tasks',
    method: 'get',
    params: query,
  })
}

export function fetchClassifyByTask(id, query) {
  return request({
    url: '/v1/admins/tasks/' + id,
    method: 'get',
    params: query,
  })
}

export function fetchItemByTask(id) {
  return request({
    url: '/v1/admins/tasks/' + id + '/item',
    method: 'get',
  })
}

export function updateClassify(id, data) {
  return request({
    url: '/v1/admins/tasks/classify/' + id,
    method: 'put',
    data
  })
}

export function deleteClassify(id) {
  return request({
    url: '/v1/admins/tasks/classify/' + id,
    method: 'delete',
  })
}

export function fetchPrimaryDate(type, query) {
  return request({
    url: '/v1/admins/analysis-basic/' + type + '/primary/dates',
    method: 'get',
    params: query,
  })
}

export function fetchNlpAnalysis(query) {
  return request({
    url: '/v1/admins/nlp-analysis',
    method: 'get',
    params: query,
  })
}

export function deleteNLPAnalysis(id) {
  return request({
    url: '/v1/admins/nlp-analysis/' + id,
    method: 'delete',
  })
}

export function fetchTaggerOptions() {
  return request({
    url: '/v1/admins/tagger-options/trend-point',
    method: 'get',
  })
}

export function fetchGptSettings(query) {
  return request({
    url: '/v1/admins/gpt-settings',
    method: 'get',
    params: query
  })
}

export function setGptSettings(data) {
  return request({
    url: '/v1/admins/gpt-settings',
    method: 'post',
    data
  })
}

export function addGptSettingsType(data) {
  return request({
    url: '/v1/admins/gpt-settings-key',
    method: 'post',
    data
  })
}

export function deleteGptSettingsType(data) {
  return request({
    url: '/v1/admins/gpt-settings-key',
    method: 'delete',
    data
  })
}

export function setGptSettingUsers(data) {
  return request({
    url: '/v1/admins/gpt-setting-users',
    method: 'post',
    data
  })
}

export function fetchTargets(query) {
  return request({
    url: '/v1/admins/gpt-target',
    method: 'get',
    params: query
  })
}

export function deleteTarget(id) {
  return request({
    url: '/v1/admins/gpt-target/' + id,
    method: 'delete',
  })
}

export function setTargetTag(id, data) {
  return request({
    url: '/v1/admins/gpt-target/' + id + '/set-tag',
    method: 'post',
    data
  })
}

export function fetchTargetLoraAvailables() {
  return request({
    url: '/v1/admins/gpt-target-lora/availables',
    method: 'get'
  })
}

export function saveTargetLora(data) {
  return request({
    url: '/v1/admins/gpt-target-lora',
    method: 'post',
    data
  })
}

export function fetchRoleMenu() {
  return request({
    url: '/v1/admins/role-menu',
    method: 'get'
  })
}

export function fetchRole(query) {
  return request({
    url: '/v1/admins/roles',
    method: 'get',
    params: query
  })
}

export function fetchOneRole(id) {
  return request({
    url: '/v1/admins/roles/' + id,
    method: 'get',
  })
}

export function createRole(data) {
  return request({
    url: '/v1/admins/roles',
    method: 'post',
    data
  })
}

export function updateRole(id, data) {
  return request({
    url: '/v1/admins/roles/' + id,
    method: 'put',
    data
  })
}

export function deleteRole(id) {
  return request({
    url: '/v1/admins/roles/' + id,
    method: 'delete',
  })
}

export function fetchAnalysisUserBrands(id, query) {
  return request({
    url: '/v1/admins/analysis/user-brands/' + id,
    method: 'get',
    params: query
  })
}

export function fetchAnalysisUserItems(id, query) {
  return request({
    url: '/v1/admins/analysis/user-items/' + id,
    method: 'get',
    params: query
  })
}

export function fetchGlobalTrendImportInfo() {
  return request({
    url: '/v1/admins/global-trends/import',
    method: 'get',
  })
}

export function fetchGptTask() {
  return request({
    url: '/v1/admins/gpt-statistics/tasks',
    method: 'get',
  })
}

export function fetchGptCore() {
  return request({
    url: '/v1/admins/gpt-statistics/cores',
    method: 'get',
  })
}

export function fetchUserGlobalTrend(id) {
  return request({
    url: '/v1/admins/global-trends/user/' + id,
    method: 'get',
  })
}

export function updateUserGlobalTrend(id, data) {
  return request({
    url: '/v1/admins/global-trends/user/' + id,
    method: 'put',
    data
  })
}

export function fetchCoreStrategy() {
  return request({
    url: '/v1/admins/gpt-cores/strategy',
    method: 'get',
  })
}

export function updateCoreStrategy(data) {
  return request({
    url: '/v1/admins/gpt-cores/strategy',
    method: 'post',
    data
  })
}